/* You can add global styles to this file, and also import other style files */
// @import '~angular-tree-component/dist/angular-tree-component.css';

html,
body {
   height: 100%;
}
input:read-only {
   background-color: #eceff1;
}
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
   opacity: 1;
   pointer-events: auto;
   position: relative;
}
[type='radio']:not(:checked),
[type='radio']:checked {
   opacity: 1;
   pointer-events: auto;
   position: relative;
}
.ams-notification {
   color: white;
   margin-top: 50px;
   z-index: 100000;
}
.k-notification-group {
   z-index: 10002;
}

.k-block {
   display: flex;
   align-items: center;
   justify-content: center;
   min-height: 80px;
}
.invalid-tab {
   background-color: red !important;
}
.prop-desc {
   font-size: small;
}
.prop-desc dt {
   border-bottom: 1px solid lightgray;

   padding: 1px;
   margin: 0;
}
.prop-desc dd {
   border-bottom: 1px solid lightgray;
   padding: 1px;
   margin: 0;
}
.error-text {
   font-size: 12px;
   margin-top: 0px;
   margin-left: 2px;
}
.table-search {
   background: white !important;
}

.k-pager-sizes.k-label.ng-star-inserted select {
   display: block !important;
}
// .trv-listviewitem.k-state-selected {
//    background: linear-gradient(40deg, #45cafc, #303f9f) !important;
//    color: white;
// }
// .trv-listviewitem.k-state-selected :hover {
//   mou
// }

.grid-header {
   background-color: #e6e6e6;
   font-weight: bold !important;
}
.grid-second-header {
   background-color: #37474f;
   font-weight: bold !important;
   color: white;
   text-align: center !important;
}
.k-filter-row td {
   padding-left: 2px !important;
   padding-right: 1px !important;
   font-size: 8px !important;
}
.k-grid .no-padding {
   padding: 2px;
}
.k-grid tr.even {
   background-color: #f9f8f8;
}
.k-grid tr.odd {
   background-color: #ffffff;
}
.k-grid tr.red {
   background: rgb(2, 0, 36);
   background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(121, 9, 21, 1) 0%, rgba(255, 0, 0, 1) 100%);
   color: white;
}
.k-grid tr.red:hover {
   background: rgb(2, 0, 36);
   background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(121, 9, 21, 1) 0%, rgba(255, 0, 0, 1) 100%);
   opacity: 0.8;
   color: white;
}
.k-grid th,
.k-grid td {
   padding: 5px 5px;
}
.k-window-titlebar {
   background: linear-gradient(40deg, #45cafc, #303f9f) !important;
   color: white;
}
.k-window-content {
   background: #e6e6e6;
}
h4 .k-input {
   color: white !important;
   font-size: 1.5em !important;
   margin-bottom: 4px;
}
.toast-custom {
   bottom: 40px;

   right: 10px;
}

.k-tooltip {
   font-size: 14px;
   max-width: 250px;
}
.k-listview {
   font-family: sans-serif;
   margin: auto;
}
.k-listview-header,
.k-listview-footer {
   background-color: #eceff1;
   font-size: 16px;
   padding: 6px 10px;
}
.k-listview-footer {
   font-size: 14px;
}

.listItemAms {
   border-bottom: solid 1px gray;
}
//ASSET REGISER
.register-amount-box {
   text-align: right !important;
   font-weight: bold;
}
// Extra Small devices (Potrait phones, 576px and down)
@media (max-width: 576px) {
   .k-window {
      width: 300px;
   }
   .k-window.k-dialog {
      width: 300px;
   }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
   .k-window {
      width: 550px;
   }
   .k-window.k-dialog {
      width: 500px;
   }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
   .k-window {
      width: 750px;
   }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
   .k-window {
      width: 1000px;
   }
}

// Menu
@media (min-width: 1200px) {
   .fixed-sn main,
   .fixed-sn .page-footer .container-fluid {
      margin-left: 1%;
      margin-right: 1%;
   }
}
@media (max-width: 1440px) {
   #reportViewer1 {
      left: 0 !important;
   }
}
